import {isProd} from "../utils/env";

const downloadLinks = {
    internal: {
        mac: 'https://fearless-files-internal.s3.eu-west-1.amazonaws.com/app-versions/Photo51-Internal-2.0.70-alpha.0.dmg',
        win: 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/app-versions/Photo51-1.0.293-alpha.exe',
    },
    production: {
        mac: 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/app-versions/Photo51-2.0.109.dmg',
        win: 'https://fearless-files-production.s3.eu-west-1.amazonaws.com/app-versions/Photo51-2.0.109.exe',
    },
}

export default isProd() ? downloadLinks.production : downloadLinks.internal;
